import React from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { ContainerBox, Table } from "components";
import SearchBox from "./SearchBox";
import { FORMGROUP_PREBUILT, PREBUILT_MODELS, TABLE_CODE } from "../../core/utils/constants"

import {
  ACCESSOR_DOCUMENT_DOWNLOAD,
  DATE_FORMAT,
  EMPTY_FORMAT_CELL,
} from "../../core/utils/constants";
import { observer, inject } from "mobx-react";
import moment from "moment";
import "./style.scss";
import BaseComponent from "../BaseComponent";

@inject('documentsDownloadStore', 'modalStore')
@observer
class ScreenDownload extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      listCheckedItems: {},
      checkAllList: {},
    };
  }

  cleanCheckedItem = () => {
    this.setState({
      listCheckedItems: {},
      checkAllList: {},
    });
  };

  isSelectedItem = (id) => {
    let isChecked = false;
    Object.keys(this.state.listCheckedItems).forEach((key) => {
      if (id === this.state.listCheckedItems[key]) {
        isChecked = true;
      }
    })
    return isChecked;
  };

  isCheckAll = (page) => {
    let isCheckAll = false;
    if (this.state.checkAllList) {
      Object.keys(this.state.checkAllList).forEach((key) => {
        if (page === parseInt(key) && this.state.checkAllList[key]) {
          isCheckAll = true;
        }
      })
    }
    return isCheckAll;
  }

  checkAllActionHandler = (e) => {
    if (e.target.checked) {
      this.addItemsToList();
    } else {
      this.removeItemsFromList();
    }

    let checkAllList = this.state.checkAllList;
    checkAllList[e.target.id] = e.target.checked;
    this.setState({
      checkAllList: checkAllList
    });
  }

  checkAllHandler = () => {
    let documents = this.props.documentsStore?.documents;
    let shouldCheck = true;
    if (documents && documents.length > 0) {
      documents.forEach((element) => {
        if (!this.isSelectedItem(element.jobid)) {
          shouldCheck = false;
        }
      });
    }

    let paging = this.props.documentsStore?.paging;
    if (paging) {
      let currentCheckedStatus = this.state.checkAllList[paging.page];
      if (shouldCheck !== currentCheckedStatus) {
        let checkAllList = this.state.checkAllList;
        checkAllList[paging.page] = shouldCheck;
        this.setState({
          checkAllList: checkAllList
        });
      }
    }
  }

  // table height
  /**
   * columns
   *
   * @returns column objects for table
   */
  columns = () => {
    const { t } = this.props;
    return [
      {
        id: "selection",
        Header: (row) => {
          let pageIndex = row.initialState?.initialTableState?.pageIndex;
          if (pageIndex || pageIndex === 0) {
            pageIndex += 1;
          }
          return <input id={pageIndex}
            className="cb-check-all"
            type="checkbox"
            checked={this.isCheckAll(pageIndex)}
            onChange={this.checkAllActionHandler} />
        },
        Cell: ({ row }) => {
          let isChecked = this.isSelectedItem(row.original.jobid);
          return (
            <div>
              <input
                id={row.original.idx}
                type="checkbox"
                value={JSON.stringify(row?.original)}
                onChange={this.handleRowSelected}
                checked={isChecked}
              />
            </div>
          );
        },
        width: "3%",
      },
      {
        Header: t("documents_download.no"),
        disableSortBy: true,
        width: "4%",
        accessor: ACCESSOR_DOCUMENT_DOWNLOAD.INDEX,
      },
      {
        Header: t("documents_download.file_name"),
        accessor: ACCESSOR_DOCUMENT_DOWNLOAD.FILE_NAME,
        width: "16%",
      },
      {
        Header: t("documents_download.form_group"),
        accessor: ACCESSOR_DOCUMENT_DOWNLOAD.FORM_GROUP,
        width: "14%",
        Cell: ({ row }) => {
          if (row?.original?.formGroupId == FORMGROUP_PREBUILT) {
            return t("documents_download.prebuilt");
          } else {
            return row?.original?.formGroupName
              ? row?.original?.formGroupName
              : EMPTY_FORMAT_CELL;
          }
        }
      },
      {
        Header: t("documents_download.definition_file"),
        accessor: ACCESSOR_DOCUMENT_DOWNLOAD.CONFIG_FILE,
        width: "15%",
        Cell: ({ row }) => {
          if (row?.original?.formGroupId == FORMGROUP_PREBUILT) {
            switch (row?.original?.configFileName) {
              case PREBUILT_MODELS.INVOICE:
                return t("documents_download.prebuilt_invoice");
              case PREBUILT_MODELS.RECEIPT:
                return t("documents_download.prebuilt_receipt");
              case PREBUILT_MODELS.CLAUDE_3_5:
                return t("documents_download.prebuilt_claude_3_5");
              default:
                return EMPTY_FORMAT_CELL;
            }
          } else {
            return row?.original?.configFileName
              ? row?.original?.configFileName
              : EMPTY_FORMAT_CELL;
          }
        }
      },
      {
        Header: t("documents_download.registered_date"),
        accessor: ACCESSOR_DOCUMENT_DOWNLOAD.CREATED_AT,
        Cell: ({ row }) =>
          row?.original?.createdAt
            ? moment(row?.original?.createdAt).format(DATE_FORMAT.full_time)
            : EMPTY_FORMAT_CELL,
        width: "10%",
      },
      {
        Header: t("documents_download.team_name"),
        accessor: ACCESSOR_DOCUMENT_DOWNLOAD.TEAMS_NAME,
        Cell: ({ row }) =>
          row?.original?.teamsName
            ? row?.original?.teamsName
            : EMPTY_FORMAT_CELL,
        width: "11%",
      },
      {
        Header: t("documents_download.user_id"),
        accessor: ACCESSOR_DOCUMENT_DOWNLOAD.USER_ID,
        Cell: ({ row }) =>
          row?.original?.userId
            ? row?.original?.userId
            : EMPTY_FORMAT_CELL,
        width: "16%",
      },
      {
        Header: t("documents_download.status"),
        id: ACCESSOR_DOCUMENT_DOWNLOAD.STATUS,
        canSort: true,
        Cell: ({ row }) => {
          return t("documents_download.status_" + row?.original?.downloadStatus);
        },
        width: "11%",
      },
    ];
  };

  /**
   * componentWillMount
   * get list documents from BE
   *
   */
  componentWillMount = () => {
    this.props.documentsDownloadStore.clean();
    this.props.documentsDownloadStore.getDocuments();
  };

  executeOnDidUpdate = () => {
    const { t } = this.props;
    let elements2 = document.getElementsByClassName("btn-download-document");
    if (elements2 && elements2[0]) {
      if (Object.keys(this.state.listCheckedItems).length !== 0) {
        elements2[0].removeAttribute("title");
      } else {
        elements2[0].setAttribute("title", t("documents_download.select_to_download"));
      }
    }

    this.checkAllHandler();
  }

  removeItemsFromList() {
    let documents = this.props.documentsDownloadStore?.documents;
    if (documents) {
      documents.forEach((element) => {
        this.removeItemFromList(element);
      });
    }
  }

  removeItemFromList(element) {
    let listCheckedItems = this.state.listCheckedItems;
    delete listCheckedItems[element.idx];
    this.setState({ listCheckedItems: listCheckedItems });
  }

  addItemsToList = () => {
    let documents = this.props.documentsDownloadStore?.documents;
    if (documents) {
      documents.forEach((element) => {
        if (element) {
          this.addItemToList(element);
        }
      });
    }
  }

  addItemToList = (element) => {
    let listCheckedItems = this.state.listCheckedItems;
    listCheckedItems[element.idx] = element.jobid;
    this.setState({ listCheckedItems: listCheckedItems });
  }

  /**
   * handleChange
   * handle select checkbox events
   * 1.update list selected items - give it to upload function later
   * 2.update disable value on list documents - for update ui
   */
  handleRowSelected = (e) => {
    let element = e.target.value;
    if (element) {
      element = JSON.parse(element);
      if (e.target.checked) {
        this.addItemToList(element);
      } else {
        this.removeItemFromList(element);
      }
    }
  };

  /**
   * handleDownload
   * handle download actions
   *
   */
  handleDownload = (data) => {
    this.props.documentsDownloadStore.downloadDocuments(
      this.state.listCheckedItems,
      this.cleanCheckedItem()
    );
  };

  /**
   * handleUpdate
   * update screen follow search filter
   *
   */
  handleUpdate = () => {
    this.cleanCheckedItem();
    this.props.documentsDownloadStore.updatePagingFiler({ page: 1 });
    this.props.documentsDownloadStore.applySearchBoxFiler();
    this.props.documentsDownloadStore.getDocuments();
  };

  renderSearchElement = () => {
    const { t } = this.props;

    return (
      <button
        className="download-btn-style btn btn-primary mb-3"
        type="button"
        onClick={this.handleUpdate}
      >
        {t("documents_download.btn_update")}
      </button>
    );
  };

  onFetch = (tableData) => {
    this.cleanCheckedItem();
    this.props.documentsDownloadStore.updatePagingFiler(tableData);
    this.props.documentsDownloadStore.getDocuments();
  };

  /**
   * render
   * render download main content
   *
   */
  render() {
    const { t } = this.props;
    let numOfCheckedItems = Object.keys(this.state.listCheckedItems).length;
    let customSelectedBox = [10, 50, 100, 500, 1000];
    return (
      <div className="screen-document">
        <ContainerBox headerTitle={t("documents_download.title")} className="search-container">
          <div className="box-body box-profile">
            <SearchBox savedData={this.props.location.state?.savedSearchState} />
          </div>
        </ContainerBox>
        <ContainerBox type="basic" className="document-container table-container">
          <div className="box-body document-item-info">
            <button
              title={t("documents_download.select_to_download")}
              disabled={Object.keys(this.state.listCheckedItems).length === 0}
              className="download-btn-style btn btn-primary f-r mb-3 btn-download-document"
              type="button"
              onClick={this.handleDownload}
            >
              {t("documents_download.download")}
            </button>
            <label className="f-r lbl-selected-item">{t("documents_download.selected_item")}{numOfCheckedItems}</label>
            <Table
              tblCode={TABLE_CODE.DOCUMENT_DOWNLOAD}
              columns={this.columns()}
              data={this.props.documentsDownloadStore?.documents}
              t={t}
              element={this.renderSearchElement()}
              customSelectedBox={customSelectedBox}
              disablePaging={false}
              onFetch={this.onFetch}
              enableServerSidePaging={true}
              initialTableState={this.props.documentsDownloadStore?.paging}
            />
          </div>
        </ContainerBox>
      </div>
    );
  }
}
export default withRouter(withTranslation()(ScreenDownload));
