import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { UploadFile, ContainerBox, ModalFooter, Table } from "components";
import { MODAL_ID, MODAL_TYPE, EMPTY_FORMAT_CELL, CONFIG_FILE_EDITION } from '../../core/utils/constants';
import { RiDeleteBin5Line } from "react-icons/ri";
import { LoadingUpload } from "../../lotties";
import "./style.scss";

@inject("commonStore", "documentsStore", "modalStore")
@observer
class ScreenUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      fileContent: null
    };
  }

  /**
   * handleBackButton
   * go edit screen
   * @param {int} id: id of document
   * 
   * @returns redirect to edit screen
   */
  handleBackButton = () => {
    this.props.documentsStore.deleteUploadFile(this.props.history)
  };


  /**
   * handleAddFile
   * @param {Array} files: list of chosen file
   * 
   * @returns rerender
   */
  handleAddFile = (file, fileContent) => {
    if (!this.props.documentsStore.updateFileContent(file)) {
      this.openModalError();
    }
  };

  openModalError = () => {
    const { modalStore, t } = this.props;
    const isIE = /MSIE|Trident/.test(window.navigator.userAgent);
    modalStore.show({
      id: MODAL_ID.CONFIRM,
      isOpen: true,
      header: t("upload.cant_upload_warning"),
      onCancel: this.closeModal,
      children: (
        <div className="text-c">
          {/* The iframe below is used to fix bug in the browser IE11.
          In the screen [Upload], the pdf iframe is overlap all elements. */}
          {isIE && <iframe class="iframe-cover-ar" src="about:blank"></iframe>}
          <div key={"modal-body"} className="modal-body">
            <span>{t("upload.cant_upload_filenum")}</span>
          </div>
          <ModalFooter
            key={"modal-footer"}
            saveButtonText={t("upload.close")}
            onConfirm={() => {
              this.closeModal();
            }}
            saveButtonClass="btn-primary"
          />
        </div>
      ),
      type: "small",
    });
  };

  handleUploadButton = () => {
    if (document.getElementById("upload-selector")) {
      document.getElementById("upload-selector").click();
    }
  }

  /**
   * handleDefinitationChange
   * @param {Int} value: new config Id
   * 
   * @returns rerender
   */
  handleDefinitationChange = (value) => {
    if (value) {
      this.props.documentsStore.updateSelectedDefinitionId(value);
    }
  }

  /**
   * handlePrebuiltFormTypeChange
   * @param {Int} value: new config Id
   * 
   * @returns rerender
   */
  handlePrebuiltFormTypeChange = (value) => {
    if (value) {
      this.props.documentsStore.updateSelectedPrebuiltFormType(value);
    }
  }

  handleTeamChange = (value) => {
    if (value) {
      this.props.documentsStore.updateSelectedTeamId(value);
    }
  }

  handleSortDefinitionChange = (value) => {
    if (value) {
      this.props.documentsStore.updateSortDefinition(value);
    }
  }

  onRead = () => {
    const {
      commonStore: { FileStore }
    } = this.props;
    FileStore.read();
  };

  /**
   * closeModal
   * close confirmation dialogue
   * 
   */
  closeModal = () => {
    const { modalStore } = this.props;
    modalStore.hide();
  };

  /**
   * openComfirmExecuteReading
   * to make sure user want to execute
   * 
   */
  openComfirmExecuteReading = () => {
    const { modalStore, documentsStore, t } = this.props;
    const isIE = /MSIE|Trident/.test(window.navigator.userAgent);
    modalStore.show({
      id: MODAL_ID.CONFIRM,
      isOpen: true,
      header: t("upload.confirmation"),
      onCancel: () => this.closeModal(MODAL_ID.CONFIRM),
      children: (
        <div className="text-c">
          {/* The iframe below is used to fix bug in the browser IE11.
          In the screen [Upload], the pdf iframe is overlap all elements. */}
          {isIE && <iframe class="iframe-cover-ar" src="about:blank"></iframe>}
          <div key={"modal-body"} className="modal-body">
            <span>{t("upload.confirm_desc")}</span>
          </div>
          <ModalFooter key={"modal-footer"}
            saveButtonText={t("upload.execute")}
            onConfirm={() => {
              this.closeModal();
              documentsStore.executeCurrentDocument(this.props.history);
            }}
            cancelButtonText={t("upload.cancel")}
            onCancel={this.closeModal}
          />
        </div>
      ),
      type: MODAL_TYPE.SMALL,
    });
  };

  deleteFile = (fileName, timestamp) => {
    const { modalStore, documentsStore, t } = this.props;
    const isIE = /MSIE|Trident/.test(window.navigator.userAgent);
    modalStore.show({
      id: MODAL_ID.CONFIRM_DELETE,
      isOpen: true,
      header: t("upload.confirmation_delete"),
      onCancel: () => this.closeModal(MODAL_ID.CONFIRM_DELETE),
      children: (
        <div className="text-c">
          {/* The iframe below is used to fix bug in the browser IE11.
          In the screen [Upload], the pdf iframe is overlap all elements. */}
          {isIE && <iframe class="iframe-cover-ar" src="about:blank"></iframe>}
          <div key={"modal-body"} className="modal-body">
            <span>{t("upload.confirm_delete_desc")}</span>
          </div>
          <ModalFooter key={"modal-footer"}
            saveButtonText={t("upload.remove")}
            onConfirm={() => {
              this.closeModal();
              documentsStore.deleteUploadedFile(encodeURIComponent(fileName), timestamp);
            }}
            cancelButtonText={t("upload.cancel")}
            onCancel={this.closeModal}
          />
        </div>
      ),
      type: MODAL_TYPE.SMALL,
    });
  }

  componentDidMount() {
    const { documentsStore, documentsStore: { listGroupDefinitions, listAllDefinitions }, commonStore: { teamStore } } = this.props;
    //const { commonStore: { templateSortingStore } } = this.props;
    documentsStore.cleanCurrentFile();
    documentsStore.clean();
    documentsStore.getDefinitationsInTeam();
    documentsStore.getDefinitationsGroupInTeam();
    documentsStore.getTemplateSortingsForUpload();
    teamStore.getTeamOfUser().then(() => {
      if (teamStore.teamsOfUser && teamStore.teamsOfUser.length > 0) {
        documentsStore.setSelectedTeamId(teamStore.teamsOfUser[0]?.id);
      }
    });
    // if (listGroupDefinitions.length <= 0 && listAllDefinitions.length > 0) {
    //   console.log("khong co group nhung co form")
    //   documentsStore.updateSortDefinition(0);
    // }
    // if (listGroupDefinitions.length > 0 && listAllDefinitions.length <= 0) {
    //   console.log("khong co form nhung co group")
    //   documentsStore.updateSortDefinition(1);
    // }
    // if (listGroupDefinitions.length <= 0 && listAllDefinitions.length <= 0) {
    //   console.log("khong co form va group")
    //   documentsStore.updateSortDefinition(0);
    // }
  }

  componentDidUpdate = () => {
    const { t, documentsStore: { selectedDefinitionId } } = this.props;
    let elements = document.getElementsByClassName("btn-execute-reading");
    if (elements && elements[0]) {
      if (this.props.documentsStore?.currentUploadFile?.readyToExecute && selectedDefinitionId !== -1) {
        elements[0].removeAttribute("title");
      } else {
        elements[0].setAttribute("title", selectedDefinitionId === -1 ? t("upload.upload_definition_to_execute_reading") : t("upload.upload_file_to_execute_reading"));
      }
    }
  }

  columns = () => {
    const { t, documentsStore: { setCurrentUploadFile } } = this.props;
    return [
      {
        Header: t("upload.no"),
        accessor: "no",
        Cell: ({ row }) => {
          return <div>{(parseInt(row.id) + 1)}</div>;
        },
        width: "6%",
        disableSortBy: true
      },
      {
        Header: t("upload.filename"),
        accessor: "name",
        Cell: ({ row }) => {
          const fileData = row.original.data || row.original;
          return fileData.status
            ? <div className="file-name single-line-row finished-item" onClick={() => setCurrentUploadFile(fileData.upload_id)} title={fileData.name} >{fileData.name}</div>
            : <div className="file-name single-line-row" title={fileData.name}>{fileData.name}</div>;
        },
        width: "44%",
        disableSortBy: true
      },
      {
        Header: t("upload.format"),
        accessor: "type",
        Cell: ({ row }) => {
          const fileData = row.original.data || row.original;
          return fileData.status ? <div>{fileData.type.split("/").slice(-1).pop()}</div> : <span>{EMPTY_FORMAT_CELL}</span>;
        },
        width: "10%",
        disableSortBy: true
      },
      {
        Header: t("upload.size"),
        accessor: "size",
        width: "20%",
        Cell: ({ row }) => {
          const fileData = row.original.data || row.original;
          return fileData.status ? <div className="single-line-row" title={fileData.size}>{fileData.size}</div> : <span>{EMPTY_FORMAT_CELL}</span>;
        },
        disableSortBy: true
      },
      {
        Header: t("upload.status"),
        accessor: "status",
        Cell: ({ row }) => {
          const fileData = row.original.data || row.original;
          return fileData.status ? <div><i className="fa fa-check"></i></div> : <div className="loading-file-status"><LoadingUpload></LoadingUpload></div>;
        },
        width: "10%",
        disableSortBy: true
      },
      {
        Header: t("upload.action"),
        accessor: "action",
        className: 'upload-action',
        Cell: ({ row }) => {
          const fileData = row.original.data || row.original;
          return <div><RiDeleteBin5Line className={fileData.status ? "" : "disabled"} onClick={() => fileData.status && this.deleteFile(fileData.name, fileData.timestamp)}></RiDeleteBin5Line></div>;
        },
        width: "10%",
        disableSortBy: true
      }
    ];
  };

  handleSelectFilter = (value) => {
    console.log("handleSelectFilter -> value", value)
    const { documentsStore: { listGroupDefinitions, updateSortDefinition, selectedGroupId, updateSelectedGroupId,
      listTemplateSortings, selectedTemplateSortingId, updateSelectedTemplateSortingId } } = this.props;
    //const { commonStore: { templateSortingStore: { templateSortingsForUpload } } } = this.props;
    updateSortDefinition(value);
    if (value === 0 || value === 2) {
      if (listGroupDefinitions[0] && selectedGroupId === null) {
        updateSelectedGroupId(listGroupDefinitions[0]?.id);
      }
    }
    if (value === 0 || value === 1) {
      if (listTemplateSortings[0] && selectedTemplateSortingId === null) {
        updateSelectedTemplateSortingId(listTemplateSortings[0]?.id);
      }
    }

    // } else {
    //   this.setState({ formGroup: null });
    //   this.handleDefinitationChange(documentsStore.listAllDefinitions[0].id);
    // }
  }

  handleGroupDefinitionChange = (value) => {
    const { documentsStore: { updateSelectedGroupId } } = this.props;
    updateSelectedGroupId(value);
    // this.handleDefinitationChange(documentsStore.listAllDefinitions?.filter(e => e.formGroupId === parseInt(value))[0].id);
  }

  handleTemplateSortingSettingChange = (value) => {
    const { documentsStore: { updateSelectedTemplateSortingId } } = this.props;
    updateSelectedTemplateSortingId(value);
  }

  render() {
    const { t, documentsStore, documentsStore: { selectedDefinitionId, selectedGroupId, sortDefinition,
      listGroupDefinitions, selectedTeamId, listTemplateSortings }, commonStore: { teamStore } } = this.props;
    //const { commonStore: { templateSortingStore: { templateSortingsForUpload } } } = this.props;
    return (
      <ContainerBox headerTitle={t("upload.report_upload")} className="screen-upload">
        <div className="screen-upload-body">
          <div className="box-body box-profile col-sm-6 su-column su-upload-pannel">
            <div className="su-sub-upload-pannel">
              <div className="su-upload-file">
                <button type="button" className="btn btn-primary upload-button" onClick={this.handleUploadButton}>
                  {t("upload.btn_select_file")}
                </button>
                <UploadFile file={documentsStore?.currentUploadFile?.content} addFile={this.handleAddFile} mime={"application/pdf, .jpg, .tiff, .png"} />
              </div>
              <div className="wrap-upload-file-list">
                <div className="upload-file-list styled-scroll">
                  {<Table
                    disablePaging={true}
                    columns={this.columns()}
                    data={documentsStore.uploadingFiles}
                    rowClass={(row) => {
                      const fileData = row.original.data || row.original;
                      return documentsStore?.currentUploadFile.upload_id === fileData.upload_id ? "selected-item" : "";
                    }}
                    t={t}
                    disableSelectionPageSize={true}
                    hideTableFooter={true}
                    className="uploading-table"
                  />}
                  {documentsStore.uploadingFiles.length === 0 && <div className="table-no-data">
                    <p>{t("table_no_data")}</p>
                  </div>}
                </div>
                <div className="row profile-item">
                  <div className="col-sm-7 select-def-label" >
                    <input
                      disabled={listTemplateSortings.length < 1}
                      type="radio"
                      id="filter2"
                      name="filter"
                      checked={sortDefinition === 2}
                      onChange={() => { }}
                      onClick={() => this.handleSelectFilter(2)}>
                    </input>
                    <label
                      htmlFor="filter2"
                      style={{ marginLeft: "8px" }}
                    >
                      {t("upload.template_sorting_filter")}
                    </label>
                  </div>
                  <div className="col-sm-5 profile-value">
                    <select
                      className="file-config"
                      disabled={sortDefinition === 0 || sortDefinition === 1 || sortDefinition === 3 || listTemplateSortings.length < 1}
                      onChange={(e) => this.handleTemplateSortingSettingChange(e.target?.value)}
                    >
                      {listTemplateSortings?.map(e => <option key={e.id} value={e.id}>{e.settingName}</option>)}
                    </select>
                  </div>
                </div>
                <div className="row profile-item">
                  <div className="col-sm-7 select-def-label" >
                    <input disabled={listGroupDefinitions.length < 1} type="radio" id="filter0" name="filter" checked={sortDefinition === 1} onChange={() => { }} onClick={() => this.handleSelectFilter(1)}></input>
                    <label htmlFor="filter0" style={{ marginLeft: "8px" }}>{t("upload.group_definition_filter")}</label>
                  </div>
                  <div className="col-sm-5 profile-value">
                    <select
                      className="file-config"
                      value={selectedGroupId}
                      disabled={sortDefinition === 0 || sortDefinition === 2 || sortDefinition === 3 || listGroupDefinitions.length < 1}
                      onChange={(e) => this.handleGroupDefinitionChange(e.target?.value)}
                    >
                      {listGroupDefinitions?.map(e => <option key={e.id} value={e.id}>{e.groupName}</option>)}
                    </select>
                  </div>
                </div>
                <div className="row profile-item">
                  <div className="col-sm-7 select-def-label">
                    <input type="radio" id="filter1" name="filter" checked={sortDefinition === 0} onChange={() => { }} onClick={() => this.handleSelectFilter(0)}></input>
                    <label htmlFor="filter1" style={{ marginLeft: "8px" }}>{t("upload.definition_file")}</label>
                  </div>
                  <div className="col-sm-5 profile-value">
                    <select disabled={sortDefinition === 1 || sortDefinition === 2 || sortDefinition === 3 || documentsStore.listAllDefinitions.length < 1} className="file-config" defaultValue={documentsStore.defaultDefinitionName} onChange={(e) => this.handleDefinitationChange(e.target?.value)}>
                      {
                        documentsStore.listAllDefinitions?.filter((def) => {
                          return def.edition != CONFIG_FILE_EDITION.PREBUILT
                        }).map((def) => {
                          return <option key={def.id} value={def.id}>{def.configName} [{def.formGroupName}]</option>
                        })
                      }
                    </select>
                  </div>
                </div>
                <div className="row profile-item">
                  <div className="col-sm-7 select-def-label">
                    <input type="radio" id="filter3" name="filter" checked={sortDefinition === 3} onChange={() => { }} onClick={() => this.handleSelectFilter(3)}></input>
                    <label htmlFor="filter3" style={{ marginLeft: "8px" }}>{t("upload.prebuilt_definition")}</label>
                  </div>
                  <div className="col-sm-5 profile-value">
                    <select disabled={sortDefinition === 0 || sortDefinition === 1 || sortDefinition === 2} className="file-config" defaultValue="-1" onChange={(e) => this.handlePrebuiltFormTypeChange(e.target?.value)}>
                      <option key="-1" value="-1">{t("upload.prebuilt_invoice")}</option>
                      <option key="-2" value="-2">{t("upload.prebuilt_receipt")}</option>
                      <option key="-3" value="-3">{t("upload.claude_3_5")}</option>
                    </select>
                  </div>
                </div>
                {/* Team name */}
                <div className="row profile-item">
                  <div className="col-sm-7 select-def-label pad-l-28">
                    <label style={{ marginLeft: "8px" }}>{t("team.team_name")}</label>
                  </div>
                  <div className="col-sm-5 profile-value">
                    <select className="file-config" onChange={(e) => this.handleTeamChange(e.target?.value)}>
                      {
                        teamStore.teamsOfUser?.map((team) => {
                          return <option key={team.id} value={team.id}>{team.name}</option>
                        })
                      }
                    </select>
                  </div>
                </div>
                <div className="action-group">
                  <button type="button" className="btn btn-danger" onClick={this.handleBackButton}>
                    {t("upload.cancel")}
                  </button>
                  <button
                    title={(selectedDefinitionId === -1) ? t("upload.upload_definition_to_execute_reading") : t("upload.upload_file_to_execute_reading")}
                    type="button" className="btn-execute-reading btn btn-primary"
                    onClick={this.openComfirmExecuteReading}
                    disabled={!documentsStore?.currentUploadFile?.readyToExecute || selectedDefinitionId === -1 || selectedTeamId === null}
                  >
                    {t("upload.execute_reading")}
                  </button>
                </div>
              </div>
            </div>
            {/* {documentsStore.listAllDefinitions.find(e => e.id === selectedDefinitionId)?.edition === "Manual" && <ReactTooltip
              id="main"
              place="top"
              type="dark"
              effect="solid"
              multiline={true}
            >
              {t("upload.sort_definition_tooltip_1")}
            </ReactTooltip>} */}
          </div>
          <div className="box-body box-profile col-sm-6 su-column su-preview">
            <iframe
              className="uploaded-file-iframe"
              frameBorder="0"
              title="uploaded-file"
              src={documentsStore?.currentUploadFile?.link}
            />
          </div>
        </div>
      </ContainerBox>
    );
  }
}
export default withRouter(withTranslation()(ScreenUpload));
